import { Router } from "@remix-run/router";

import { RouteObject, createBrowserRouter } from "react-router-dom";

import RouterErrorBoundary from "@aglocal/web/components/RouterErrorBoundary";

import TalentAssistantApp from "@/pages/TalentAssistantApp";

export const routes: RouteObject[] = [
  {
    path: "/app.html",
    errorElement: <RouterErrorBoundary />,
    element: <TalentAssistantApp />,
  },
];

const router: Router = createBrowserRouter(routes, {
  basename: import.meta.env.BASE_URL,
});

export default router;
