import { Schema } from "effect";
export const MeetingTranscriptItem = Schema.Struct({
    speaker: Schema.String,
    text: Schema.String,
});
export const MeetingTranscript = Schema.Array(MeetingTranscriptItem);
export const MeetingContext = Schema.Struct({
    interviewer: Schema.String,
    interviewee: Schema.String,
    startTime: Schema.String,
    now: Schema.optional(Schema.String),
});
export const SummaryPrompts = Schema.Struct({
    system: Schema.String,
    stuff: Schema.String,
    map: Schema.String,
    reduce: Schema.String,
    refine: Schema.String,
    revise: Schema.String,
});
export const MeetingSummary = Schema.String;
export const SystemTrace = Schema.Struct({
    type: Schema.Literal("system"),
    prompt: Schema.String,
});
export const PromptTrace = Schema.Struct({
    type: Schema.Literal("prompt"),
    prompt: Schema.String,
    response: Schema.String,
});
export const Trace = Schema.Union(SystemTrace, PromptTrace);
export const MeetingSummaryOutput = Schema.Struct({
    summary: Schema.String,
    trace: Schema.NullishOr(Schema.Array(Trace)),
});
