import { FormEventHandler, useCallback, useState } from "react";
import { useIntl } from "react-intl";

import {
  Button,
  Field,
  SearchBox,
  makeStyles,
} from "@fluentui/react-components";

import type { Interview } from "@aglocal/schema/Interview";
import type { ContactSearchItem } from "@aglocal/schema/ContactSearchResult";

import Container from "@aglocal/web/components/Container";
import Callout from "@aglocal/web/components/Callout";
import Stack from "@aglocal/web/components/Stack/Stack";
import { Heading, Text } from "@aglocal/web/components/Typography";

import useAsync from "@aglocal/web/hooks/useAsync";
import useError from "@aglocal/web/hooks/useError";
import { useMissionControl } from "@aglocal/web/mission-control";

import TeamsBackground from "@/components/TeamsBackground";
import ContactResults from "@/components/ContactResults";

const useStyles = makeStyles({
  searchField: {
    display: "flex",
    maxWidth: "800px",
  },
  searchInput: {
    flexGrow: 1,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  searchButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

export interface ContactSelectorTabProps {
  interview: Interview;
}

export default function ContactSelector({
  interview: { id },
}: ContactSelectorTabProps) {
  const { $t } = useIntl();
  const styles = useStyles();

  const api = useMissionControl();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useError();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermInput, setSearchTermInput] = useState("");

  const recentContacts = useAsync(
    async (signal) =>
      await api.findConnectedContacts({ iId: id, searchTerm: "" }, { signal }),
    [api, id],
  );

  const searchContacts = useAsync(
    async (signal) => {
      if (searchTerm.trim() === "") {
        return null;
      }
      return await api.findConnectedContacts(
        { iId: id, searchTerm },
        { signal },
      );
    },
    [api, id, searchTerm],
  );

  const setContact = useCallback(
    (contact: ContactSearchItem) => {
      setLoading(true);
      api
        .setConnectedContact({
          iId: id,
          contactId: contact.id,
        })
        .catch((error: unknown) => {
          reportError(error);
          setError(error);
          setLoading(false);
        });
    },
    [api, id, setLoading, setError],
  );

  const handleSearch: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setSearchTerm(searchTermInput);
  };

  return (
    <TeamsBackground>
      <Container>
        <Heading variant="title3">
          Connect this meeting to a candidate profile
        </Heading>

        <Text variant="subtitle2">
          To enable interview insights and candidate tracking, please link this
          meeting to a candidate profile in Connected.
        </Text>

        <Stack gap="m">
          {error != null && (
            <Callout intent="error" title="An error occurred while searching.">
              Please try again or contact support if the issue persists.
            </Callout>
          )}

          <form onSubmit={handleSearch}>
            <Field className={styles.searchField} required>
              <SearchBox
                name="query"
                className={styles.searchInput}
                placeholder={$t({
                  defaultMessage: "Search by candidate name, ID or email",
                  description:
                    "Placeholder text for the full name input field.",
                })}
                value={searchTermInput}
                onChange={(event, data) => {
                  setSearchTermInput(data.value);
                }}
              />
              <Button
                type="submit"
                appearance="primary"
                className={styles.searchButton}
                disabled={searchContacts.loading}
              >
                Search
              </Button>
            </Field>
          </form>

          <ContactResults
            error={searchContacts.error}
            loading={searchContacts.loading}
            contacts={searchContacts.result?.contacts ?? null}
            disabled={loading}
            onSelect={setContact}
          />

          <Heading as="h2" variant="subtitle1">
            Recently viewed contacts
          </Heading>

          <ContactResults
            error={recentContacts.error}
            loading={recentContacts.loading}
            contacts={recentContacts.result?.contacts ?? null}
            disabled={loading}
            onSelect={setContact}
          />
        </Stack>
      </Container>
    </TeamsBackground>
  );
}
