export function now() {
    return new Date();
}
export function timestamp(value) {
    if (value == null) {
        return Date.now() / 1000;
    }
    return value.getTime() / 1000;
}
export function offset(date, offset, sign = 1) {
    if (offset.years) {
        date.setFullYear(date.getFullYear() + sign * offset.years);
    }
    if (offset.months) {
        date.setMonth(date.getMonth() + sign * offset.months);
    }
    if (offset.days) {
        date.setDate(date.getDate() + sign * offset.days);
    }
    if (offset.hours) {
        date.setHours(date.getHours() + sign * offset.hours);
    }
    if (offset.minutes) {
        date.setMinutes(date.getMinutes() + sign * offset.minutes);
    }
    if (offset.seconds) {
        date.setSeconds(date.getSeconds() + sign * offset.seconds);
    }
    return date;
}
export function future(duration) {
    return offset(new Date(), duration);
}
export function past(duration) {
    return offset(new Date(), duration, -1);
}
export function getUTCOffset(value) {
    // Because a bug at FF24, we're rounding the timezone offset around 15 minutes
    // https://github.com/moment/moment/pull/1871
    return -Math.round(value.getTimezoneOffset() / 15) * 15;
}
function padStart(value, length, pad) {
    if (value.length >= length)
        return value;
    return `${Array(length + 1 - value.length).join(pad)}${value}`;
}
function padNumber(value, length) {
    return padStart(String(value), length, "0");
}
export function formatISOTimezone(value) {
    const negMinutes = -getUTCOffset(value);
    const minutes = Math.abs(negMinutes);
    const hourOffset = Math.floor(minutes / 60);
    const minuteOffset = minutes % 60;
    const sign = negMinutes <= 0 ? "+" : "-";
    const formattedHours = padNumber(hourOffset, 2);
    const formattedMinutes = padNumber(minuteOffset, 2);
    return `${sign}${formattedHours}:${formattedMinutes}`;
}
export function formatLocalISO(value) {
    const YYYY = padNumber(value.getFullYear(), 4);
    const MM = padNumber(value.getMonth() + 1, 2);
    const DD = padNumber(value.getDate(), 2);
    const HH = padNumber(value.getHours(), 2);
    const mm = padNumber(value.getMinutes(), 2);
    const ss = padNumber(value.getSeconds(), 2);
    const SSS = padNumber(value.getMilliseconds(), 3);
    const Z = formatISOTimezone(value);
    return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}.${SSS}${Z}`;
}
