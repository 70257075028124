import { FormattedMessage, useIntl } from "react-intl";

import { makeStyles, Spinner } from "@fluentui/react-components";

import { ContactSearchItem } from "@aglocal/schema/ContactSearchResult";

import Callout from "@aglocal/web/components/Callout";
import { Text } from "@aglocal/web/components/Typography";

import ContactDetails from "@/components/ContactDetails";
import { Grid, Col, Row } from "@aglocal/web/components/Grid";

const useStyles = makeStyles({
  container: {},
});

export interface ContactResultsProps {
  error: unknown;
  loading: boolean;
  disabled: boolean;
  contacts: readonly ContactSearchItem[] | null;
  onSelect: (contact: ContactSearchItem) => void;
}

export default function ContactResults({
  error,
  loading,
  disabled,
  contacts,
  onSelect,
}: ContactResultsProps) {
  const { $t } = useIntl();
  const styles = useStyles();

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Callout
        intent="error"
        title={$t({
          defaultMessage: "An error occurred while fetching contacts.",
          description:
            "Error message to show user when fetching contacts has failed.",
        })}
      ></Callout>
    );
  }

  if (contacts == null) {
    return null;
  }

  if (contacts.length == 0) {
    <Text>
      <FormattedMessage
        defaultMessage="No contacts found."
        description="Message to show the user that no contacts were found."
      />
    </Text>;
  }

  return (
    <Grid className={styles.container}>
      <Row>
        {contacts.map((contact) => (
          <Col key={contact.id} sm="12" xl="4">
            <ContactDetails
              contact={contact}
              disabled={disabled}
              onSelect={onSelect}
            />
          </Col>
        ))}
      </Row>
    </Grid>
  );
}
