import { jsx as _jsx } from "@fluentui/react-jsx-runtime/jsx-runtime";
/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import { forwardRef } from "react";
import { assertSlots, getIntrinsicElementProps, mergeClasses, makeStyles, slot, } from "@fluentui/react-components";
import clearfix from "@aglocal/web/styles/clearfix";
const useStyles = makeStyles({
    root: {
        margin: "0 -8px",
        boxSizing: "border-box",
        zoom: 1,
        ...clearfix(),
    },
});
function useRow(props, ref) {
    return {
        components: { root: "div" },
        root: slot.always(getIntrinsicElementProps("div", { ref, ...props }), {
            elementType: "div",
        }),
    };
}
function useRowStyles(state) {
    const styles = useStyles();
    state.root.className = mergeClasses(styles.root, state.root.className);
}
function renderRow(state) {
    assertSlots(state);
    return _jsx(state.root, {});
}
export const Row = forwardRef((props, ref) => {
    const state = useRow(props, ref);
    useRowStyles(state);
    return renderRow(state);
});
Row.displayName = "Row";
export default Row;
