function identity(value) {
    return value;
}
class UniqueList {
    getKey;
    _keys = new Set();
    _values = [];
    static copy(list) {
        const result = new UniqueList(null, list.getKey);
        result._values = [...list._values];
        result._keys = new Set(list._keys);
        return result;
    }
    static union(...lists) {
        if (lists.length === 0) {
            return new UniqueList();
        }
        const result = UniqueList.copy(lists[0]);
        for (let i = 1; i < lists.length; i++) {
            if (lists[i].getKey !== result.getKey) {
                throw new Error("All lists must have the same key type");
            }
            lists[i]._values.forEach((value) => {
                result.add(value);
            });
        }
        return result;
    }
    static difference(list1, list2) {
        if (list1.getKey !== list2.getKey) {
            throw new Error("All lists must have the same key type");
        }
        return new UniqueList(list1._values.filter((value) => !list2.contains(value)), list1.getKey);
    }
    constructor(values, getKey = identity) {
        this.getKey = getKey;
        values?.forEach((value) => {
            this.add(value);
        });
    }
    contains(value) {
        const key = this.getKey(value);
        return this._keys.has(key);
    }
    add(value) {
        const key = this.getKey(value);
        if (this._keys.has(key)) {
            return this;
        }
        this._keys.add(key);
        this._values.push(value);
        return this;
    }
    remove(value) {
        const key = this.getKey(value);
        if (!this._keys.has(key)) {
            return this;
        }
        this._keys.delete(key);
        this._values = this._values.filter((s) => this.getKey(s) !== key);
        return this;
    }
    some(predicate) {
        return this._values.some((value, index) => predicate(value, index, this));
    }
    every(predicate) {
        return this._values.every((value, index) => predicate(value, index, this));
    }
    map(callback) {
        return this._values.map((value, index) => callback(value, index, this));
    }
    filter(predicate) {
        return new UniqueList(this._values.filter((value, index) => predicate(value, index, this)), this.getKey);
    }
    values() {
        return this._values.values();
    }
    [Symbol.iterator]() {
        return this._values.values();
    }
    get length() {
        return this._values.length;
    }
}
export default UniqueList;
