export class ValueError extends Error {
    value;
    constructor(value) {
        super(String(value));
        this.value = value;
        this.name = typeof value;
    }
}
export class ObjectError extends Error {
    value;
    constructor(value) {
        super(ObjectError.getMessage(value));
        this.value = value;
        this.name = value.constructor.name;
    }
    static getMessage(value) {
        if ("message" in value && typeof value.message === "string") {
            return value.message;
        }
        if (value.toString !== Object.prototype.toString) {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            return value.toString();
        }
        try {
            return JSON.stringify(value);
        }
        catch {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            return String(value);
        }
    }
}
export class ResponseError extends Error {
    response;
    constructor(response) {
        super(response.statusText);
        this.response = response;
        this.name = "ResponseError";
    }
}
function isError(value) {
    return ("name" in value &&
        typeof value.name === "string" &&
        "message" in value &&
        typeof value.message === "string" &&
        (!("stack" in value) || typeof value.stack === "string"));
}
export default function asError(value) {
    if (value instanceof Error) {
        return value;
    }
    if (value instanceof Response) {
        return new ResponseError(value);
    }
    if (value == null) {
        return new Error();
    }
    if (typeof value !== "object" || Array.isArray(value)) {
        return new ValueError(value);
    }
    if (isError(value)) {
        return value;
    }
    return new ObjectError(value);
}
