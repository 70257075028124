import { Schema } from "effect";
import { BaseFireStoreObject } from "./BaseFirestoreObject.js";
export const PresenceStatus = Schema.Literal("Lobby", "Incall", "CallEnd", "None");
export const PresenceStatePayload = Schema.Struct({
    status: PresenceStatus,
    leaving: Schema.Boolean,
});
export const PresenceStatusPointInTime = Schema.Struct({
    status: PresenceStatus,
    leaving: Schema.Boolean,
    at: Schema.Int,
});
export const PresenceStatusTimePeriod = Schema.extend(PresenceStatusPointInTime, Schema.Struct({
    until: Schema.Int,
}));
export const InterviewPresenceStatus = Schema.extend(BaseFireStoreObject, Schema.Struct({
    latestIntervieweePresenceStatus: PresenceStatusPointInTime,
    lastIntervieweeInLobbyNotificationDateTime: Schema.optional(Schema.Int),
    intervieweePresenceHistory: Schema.optional(Schema.Array(PresenceStatusTimePeriod)),
}));
