import { ReactNode, useEffect, useState } from "react";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { MSAL_CONFIG } from "./config";
import MsalAuthenticated from "./MsalAuthenticated";

export interface AuthProviderProps {
  children: ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [instance, setInstance] = useState<PublicClientApplication | null>(
    null,
  );

  useEffect(() => {
    // Use useEffect to create the MSAL instance asynchronously
    const createInstance = async () => {
      const newInstance =
        await PublicClientApplication.createPublicClientApplication(
          MSAL_CONFIG,
        );
      setInstance(newInstance as PublicClientApplication);
    };
    void createInstance();
  }, []);

  if (!instance) {
    // Render loading state while instance is being created
    return; // Or any other loading indicator
  }

  return (
    <MsalProvider instance={instance}>
      <MsalAuthenticated>{children}</MsalAuthenticated>
    </MsalProvider>
  );
}
