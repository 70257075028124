import { Schema } from "effect";
export const CaptionsResultType = Schema.Literal("Partial", "Final");
/**
 * Phone Number
 */
export const PhoneNumberIdentifier = Schema.Struct({
    /**
     * Optional raw id of the phone number.
     */
    rawId: Schema.optional(Schema.String),
    /**
     * The phone number in E.164 format.
     */
    phoneNumber: Schema.String,
});
export const PhoneNumberKind = Schema.asSchema(PhoneNumberIdentifier.pipe(Schema.extend(Schema.Struct({
    kind: Schema.Literal("phoneNumber"),
}))));
/**
 * Communication User
 */
export const CommunicationUserIdentifier = Schema.Struct({
    communicationUserId: Schema.String,
});
export const CommunicationUserKind = Schema.asSchema(CommunicationUserIdentifier.pipe(Schema.extend(Schema.Struct({
    kind: Schema.Literal("communicationUser"),
}))));
/**
 * Teams User
 */
export const MicrosoftTeamsUserIdentifier = Schema.Struct({
    /**
     * Optional raw id of the Microsoft Teams user.
     */
    rawId: Schema.optional(Schema.String),
    /**
     * Id of the Microsoft Teams user. If the user isn't anonymous, the id is the AAD object id of the user.
     */
    microsoftTeamsUserId: Schema.String,
    /**
     * True if the user is anonymous, for example when joining a meeting with a share link. If missing, the user is not anonymous.
     */
    isAnonymous: Schema.optional(Schema.Boolean),
    /**
     * The cloud that the Microsoft Teams user belongs to. If missing, the cloud is "public".
     */
    cloud: Schema.optional(Schema.Literal("public", "dod", "gcch")),
});
export const MicrosoftTeamsUserKind = Schema.asSchema(MicrosoftTeamsUserIdentifier.pipe(Schema.extend(Schema.Struct({
    kind: Schema.Literal("microsoftTeamsUser"),
}))));
/**
 * Teams App
 */
export const MicrosoftTeamsAppIdentifier = Schema.Struct({
    /**
     * Optional raw id of the Microsoft Teams App.
     */
    rawId: Schema.optional(Schema.String),
    /**
     * The unique Microsoft Teams app ID.
     */
    teamsAppId: Schema.String,
    /**
     * The cloud that the Microsoft Temas App belongs to. If missing, the cloud is "public".
     */
    cloud: Schema.optional(Schema.Literal("public", "dod", "gcch")),
});
export const MicrosoftTeamsAppKind = Schema.asSchema(MicrosoftTeamsAppIdentifier.pipe(Schema.extend(Schema.Struct({
    kind: Schema.Literal("microsoftTeamsApp"),
}))));
/**
 * Unknown Identifier
 */
export const UnknownIdentifier = Schema.Struct({
    /**
     * Id of the UnknownIdentifier.
     */
    id: Schema.String,
});
export const UnknownKind = Schema.asSchema(UnknownIdentifier.pipe(Schema.extend(Schema.Struct({
    kind: Schema.Literal("unknown"),
}))));
/**
 * Union of all Identifier Types
 */
export const CommunicationIdentifierKind = Schema.Union(CommunicationUserKind, PhoneNumberKind, MicrosoftTeamsUserKind, MicrosoftTeamsAppKind, UnknownKind);
/**
 * Caller Info
 */
export const CallerInfo = Schema.Struct({
    /**
     * Identifier of the caller.
     */
    identifier: Schema.Union(CommunicationIdentifierKind, Schema.Undefined),
    /**
     * Display name of caller ( optional )
     */
    displayName: Schema.optional(Schema.String),
});
/**
 * Captions Info
 */
export const CaptionsInfo = Schema.Struct({
    /**
     * The state in which this caption data can be classified.
     */
    resultType: CaptionsResultType,
    /**
     * The information of the call participant who spoke the captioned text.
     */
    speaker: CallerInfo,
    /**
     * The language that the spoken words were interpretted as. Corresponds to the language specified in startCaptions / setSpokenLanguage.
     */
    spokenLanguage: Schema.String,
    /**
     * The caption text.
     */
    captionText: Schema.String,
    /**
     * Timestamp of when the captioned words were initially spoken.
     */
    timestamp: Schema.Date,
    /**
     * Timestamp of when the captions were last updated.
     */
    lastUpdatedTimestamp: Schema.optional(Schema.Date),
    /**
     * The language that the captions are presented in. Corresponds to the captionLanguage specified in startCaptions / setCaptionLanguage.
     */
    captionLanguage: Schema.optional(Schema.String),
    /**
     * The original spoken caption text prior to translating to subtitle language
     */
    spokenText: Schema.optional(Schema.String),
});
export const InterviewCaptionsInfo = Schema.asSchema(CaptionsInfo.pipe(Schema.extend(Schema.Struct({
    iId: Schema.UUID,
}))));
