import { useEffect, useState } from "react";

import { updateDoc } from "firebase/firestore";

import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Combobox,
  Field,
  Option,
} from "@fluentui/react-components";

import type { Interview } from "@aglocal/schema/Interview";

import { useMissionControlDB } from "@aglocal/web/firebase";

const LANGUAGE_OPTIONS = [
  { value: "en", label: "English" },
  { value: "es", label: "Español (Spanish)" },
  { value: "fr", label: "Français (French)" },
  { value: "de", label: "Deutsch (German)" },
  { value: "it", label: "Italiano (Italian)" },
];

function getLanguageLabel(value: string) {
  return (
    LANGUAGE_OPTIONS.find((option) => option.value === value)?.label ?? value
  );
}

function getLanguageValue(label: string) {
  return (
    LANGUAGE_OPTIONS.find((option) => option.label === label)?.value ?? "en"
  );
}

export interface NotesAccordionItemProps {
  name: unknown;
  interview: Interview;
}

export default function NotesAccordionItem({
  name,
  interview,
}: NotesAccordionItemProps) {
  const db = useMissionControlDB();

  const defaultLanguage =
    interview.selectedLanguage ?? interview.detectedLanguage ?? null;

  const [language, setLanguage] = useState(defaultLanguage);
  const label = getLanguageLabel(language ?? "en");

  useEffect(() => {
    setLanguage((currentLanguage) => currentLanguage ?? defaultLanguage);
  }, [defaultLanguage]);

  const updateLanguage = (value: string) => {
    setLanguage(value);
    void updateDoc(db.interviews.doc(interview.id), {
      selectedLanguage: value,
    });
  };

  return (
    <AccordionItem value={name}>
      <AccordionHeader>Language Selection</AccordionHeader>
      <AccordionPanel>
        <Field
          label="Language"
          hint={
            interview.detectedLanguage &&
            `Detected language: ${getLanguageLabel(interview.detectedLanguage)}`
          }
        >
          <Combobox
            value={label}
            onOptionSelect={(event, { optionValue }) => {
              if (optionValue) updateLanguage(getLanguageValue(optionValue));
            }}
          >
            {LANGUAGE_OPTIONS.map(({ value, label }) => (
              <Option key={value} value={label}>
                {label}
              </Option>
            ))}
          </Combobox>
        </Field>
      </AccordionPanel>
    </AccordionItem>
  );
}
