import { Schema } from "effect";
export const InterviewSummary = Schema.Struct({
    iId: Schema.NonEmptyString,
    detectedLanguage: Schema.String,
    intervieweeConnectedId: Schema.String,
    intervieweeEmail: Schema.String,
    intervieweeName: Schema.String,
    interviewerEmail: Schema.String,
    interviewerName: Schema.String,
    meetingStatus: Schema.String,
    hasTranscript: Schema.optionalWith(Schema.Boolean, { default: () => false }),
    startTimeDate: Schema.String,
    endTimeDate: Schema.String,
});
