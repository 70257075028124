import { timestamp } from "@aglocal/web/helpers/time";
export class AuthError extends Error {
    constructor(message) {
        super(message);
        this.name = "AuthError";
    }
}
function isExpired(state) {
    if (state == null) {
        return true;
    }
    if (state.expires == null) {
        return false;
    }
    return state.expires < timestamp();
}
export class CachedTokenAuthManager {
    fetchToken;
    state = null;
    pending = null;
    constructor(fetchToken) {
        this.fetchToken = fetchToken;
    }
    async getToken(signal) {
        if (this.pending == null && !isExpired(this.state)) {
            return this.state.token;
        }
        if (this.pending == null) {
            const controller = new AbortController();
            const state = this.fetchToken(controller.signal)
                .then((state) => {
                this.state = state;
                return state.token;
            })
                .finally(() => {
                this.pending = null;
            });
            this.pending = {
                state,
                controller,
                signals: new Set(),
            };
        }
        if (signal == null) {
            return await this.pending.state;
        }
        const pending = this.pending;
        pending.signals.add(signal);
        return await Promise.race([
            pending.state,
            new Promise((_, reject) => {
                signal.addEventListener("abort", () => {
                    reject(signal.reason);
                    pending.signals.delete(signal);
                    if (pending.signals.size === 0) {
                        pending.controller.abort();
                    }
                });
            }),
        ]);
    }
    async reset(abort, reason) {
        if (this.pending !== null) {
            if (abort) {
                this.pending.controller.abort(reason);
            }
            this.pending = null;
        }
        this.state = null;
        return Promise.resolve();
    }
}
export class AnonymousAuthManager {
    getToken() {
        return Promise.reject(new AuthError("Not authenticated"));
    }
    reset() {
        return Promise.resolve();
    }
}
