import { Schema } from "effect";
export const QuestionAnswerChunkScore = Schema.Struct({
    model: Schema.String,
    score: Schema.Number,
});
export const QuestionAnswerChunk = Schema.Struct({
    text: Schema.String,
    maxScore: Schema.Number,
    scores: Schema.Array(QuestionAnswerChunkScore),
});
export const QuestionAnswer = Schema.Struct({
    raw: Schema.String,
    text: Schema.String,
    score: Schema.Number,
});
export const QuestionAnswerOutput = Schema.Struct({
    iid: Schema.String,
    question: Schema.String,
    chunks: Schema.Array(QuestionAnswerChunk),
    answer: Schema.optional(QuestionAnswer),
});
