import { useEffect, useMemo, useRef, useSyncExternalStore } from "react";

import type { Interview } from "@aglocal/schema/Interview";
import type {
  MeetingTranscript,
  MeetingContext,
} from "@aglocal/schema/MeetingSummary";

import {
  type MeetingSummarySettings,
  type MissionControlAPI,
  useMissionControl,
} from "@aglocal/web/mission-control";
import asError from "@aglocal/web/helpers/asError";
import useTranscript from "@aglocal/web/firebase/queries/useTranscript";
import {
  formatTranscript,
  getContext,
} from "@aglocal/web/helpers/meetingSummary";

const MODELS = [
  {
    name: "Duilia",
    settings: {
      model: "gemini-2.0-flash-lite",
      pattern: "STUFF",
    },
  },
  {
    name: "Eugene",
    settings: { model: "gemini-2.0-flash", pattern: "STUFF" },
  },
  { name: "Albert", settings: { model: "gemini-1.5-flash", pattern: "STUFF" } },
  { name: "Beatrice", settings: { model: "gemini-1.5-pro", pattern: "STUFF" } },
] as const satisfies { name: string; settings: MeetingSummarySettings }[];

export type ModelName = (typeof MODELS)[number]["name"];

export interface Summary {
  readonly name: ModelName;
  readonly value: string;
  readonly settings: MeetingSummarySettings;
}

export interface SummarySnapshot {
  summary: Summary | null;
  regenerate: () => Promise<Summary>;
  loading: boolean;
  error?: Error;
}

class SummaryStore {
  private index = -1;
  private transcript?: MeetingTranscript;
  private summaries: Summary[] = [];
  private snapshot: SummarySnapshot;
  private callbacks = new Set<() => void>();

  constructor(
    private api: MissionControlAPI,
    private context: MeetingContext,
  ) {
    this.snapshot = {
      summary: null,
      loading: true,
      regenerate: () => this.regenerate(),
    };
  }

  public subscribe(callback: () => void): () => void {
    this.callbacks.add(callback);
    return () => this.callbacks.delete(callback);
  }

  public getSnapshot(): Readonly<SummarySnapshot> {
    return this.snapshot;
  }

  private publish(update: Partial<SummarySnapshot>): void {
    this.snapshot = { ...this.snapshot, ...update };
    this.callbacks.forEach((callback) => {
      callback();
    });
  }

  private async fetchSummary(
    settings: MeetingSummarySettings,
  ): Promise<string> {
    if (!this.transcript) {
      throw new Error("no transcript set");
    }

    if (this.transcript.length === 0) {
      return "";
    }

    const { summary } = await this.api.getMeetingSummary({
      context: this.context,
      transcript: this.transcript,
      settings,
    });

    return summary;
  }

  public async regenerate(): Promise<Summary> {
    this.index = (this.index + 1) % MODELS.length;

    if (this.index < this.summaries.length) {
      const summary = this.summaries[this.index];
      this.publish({ summary, loading: false, error: undefined });
      return summary;
    }

    this.publish({ loading: true, error: undefined });

    try {
      const { name, settings } = MODELS[this.index];
      const value = await this.fetchSummary(settings);

      const summary = (this.summaries[this.index] = {
        name,
        value,
        settings,
      });

      this.publish({
        summary,
        loading: false,
        error: undefined,
      });

      return summary;
    } catch (error: unknown) {
      this.publish({ loading: false, error: asError(error) });
      throw error;
    }
  }

  public setTranscript(transcript: MeetingTranscript): void {
    this.transcript = transcript;
    void this.regenerate();
  }

  public setError(error: Error): void {
    this.publish({ loading: false, error });
  }
}

export default function useSummary(interview: Interview): SummarySnapshot {
  const api = useMissionControl();
  const { transcript, error } = useTranscript(interview.id, interview);

  const [store, subscribe, getSnapshot] = useMemo(() => {
    const context = getContext(interview);
    const store = new SummaryStore(api, context);
    return [store, store.subscribe.bind(store), store.getSnapshot.bind(store)];
  }, [api, interview]);

  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) {
      return;
    }

    const updateStore = (): boolean => {
      if (error != null) {
        store.setError(error);
        return true;
      }

      if (transcript != null) {
        store.setTranscript(formatTranscript(transcript));
        return true;
      }

      return false;
    };

    initialized.current = updateStore();
  }, [initialized, store, transcript, error]);

  return useSyncExternalStore(subscribe, getSnapshot);
}
