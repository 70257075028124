import { jsx as _jsx } from "@fluentui/react-jsx-runtime/jsx-runtime";
/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import { forwardRef } from "react";
import { makeStyles } from "@fluentui/react-components";
import { assertSlots, getIntrinsicElementProps, mergeClasses, slot, } from "@fluentui/react-components";
import clearfix from "@aglocal/web/styles/clearfix";
const useStyles = makeStyles({
    root: {
        boxSizing: "border-box",
        zoom: 1,
        padding: "0 8px",
        ...clearfix(),
    },
});
function useGrid(props, ref) {
    return {
        components: { root: "div" },
        root: slot.always(getIntrinsicElementProps("div", { ref, ...props }), {
            elementType: "div",
        }),
    };
}
function useGridStyles(state) {
    const styles = useStyles();
    state.root.className = mergeClasses(styles.root, state.root.className);
}
function renderGrid(state) {
    assertSlots(state);
    return _jsx(state.root, {});
}
export const Grid = forwardRef((props, ref) => {
    const state = useGrid(props, ref);
    useGridStyles(state);
    return renderGrid(state);
});
Grid.displayName = "Grid";
export default Grid;
