import { HTTPStatusDescriptions } from "./http";
export { HTTPStatus } from "./http";
export class AbortError extends Error {
    status;
    reason;
    constructor(status, reason, message) {
        super(message);
        this.status = status;
        this.reason = reason;
    }
}
export default function abort(status, description) {
    const message = HTTPStatusDescriptions[status];
    throw new AbortError(status, message.reason, description ?? message.description ?? "");
}
