import { Schema } from "effect";
export const LobbyDetails = Schema.Struct({
    interviewer: Schema.String,
    interviewee: Schema.String,
    startTimeDate: Schema.Date,
    endTimeDate: Schema.Date,
    teamsToken: Schema.String,
    meetingJoinUrl: Schema.String,
    recordingOptOut: Schema.Boolean,
});
