import { Schema } from "effect";
import { objectToCamel, objectToSnake } from "ts-case-convert";
import { FirebaseToken } from "@aglocal/schema/FirebaseToken";
import { InterviewAudio } from "@aglocal/schema/InterviewAudio";
import { MeetingSummaryOutput, SummaryPrompts, } from "@aglocal/schema/MeetingSummary";
import { QuestionAnswerOutput } from "@aglocal/schema/QuestionAnswer";
import { TalentMeetingDetails } from "@aglocal/schema/TalentMeetingDetails";
import HttpAPI, { ResponseError } from "../http";
import { ContactSearchRequest, ContactSearchResult, ContactSelectRequest, } from "@aglocal/schema/ContactSearchResult";
const SnakeCaseEncoding = {
    serialize: (data) => JSON.stringify(objectToSnake(data)),
    deserialize: (data) => objectToCamel(JSON.parse(data)),
};
export default class HttpMissionControlAPI extends HttpAPI {
    async getInterviewAudio(iid, options) {
        try {
            const { url } = await this.request({
                schema: InterviewAudio,
                method: "GET",
                url: `/audiostream/${iid}/mp3`,
                options,
            });
            return url;
        }
        catch (error) {
            if (error instanceof ResponseError && error.status === 404) {
                return null;
            }
            throw error;
        }
    }
    async getQuestionAnswer(data, options) {
        return await this.request({
            schema: QuestionAnswerOutput,
            encoding: SnakeCaseEncoding,
            method: "POST",
            url: "/models/question-answer/",
            data,
            options,
        });
    }
    async getMeetingSummaryPrompts(options) {
        return await this.request({
            schema: SummaryPrompts,
            method: "GET",
            url: "/models/meeting-summary/prompts/",
            options,
        });
    }
    async getMeetingSummary(data, options) {
        return await this.request({
            schema: MeetingSummaryOutput,
            encoding: SnakeCaseEncoding,
            method: "POST",
            url: "/models/meeting-summary/infer/",
            data,
            options,
        });
    }
    async extractMeetingDetails(data, options) {
        return await this.request({
            schema: TalentMeetingDetails,
            encoding: SnakeCaseEncoding,
            method: "POST",
            url: "/models/meeting-details/",
            data,
            options,
        });
    }
    async getFirebaseToken(options) {
        const { token } = await this.request({
            schema: FirebaseToken,
            method: "POST",
            url: "/api/firebase/gettoken",
            options,
        });
        return token;
    }
    async startMeetingBot(data, options) {
        await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: "/api/recall/startbot",
            data: { botName: "AGi Talent Assistant", ...data },
            options,
        });
    }
    async stopMeetingBot(data, options) {
        await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: "/api/recall/stopbot",
            data,
            options,
        });
    }
    async setMeetingStatus(data, options) {
        await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: "/api/meeting/setmeetingstatus",
            data,
            options,
        });
    }
    async updateConnectedDetails(data, options) {
        await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: "/api/meeting/putg2details",
            data,
            options,
        });
    }
    async postMeetingCleanup({ iId, ...data }, options) {
        await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: `/interview/postmeetingcleanup/${iId}`,
            data,
            options,
        });
    }
    commuteQuery(data) {
        return new URLSearchParams({
            office: "aer-milwaukee, wi - p&i ac - ac-01435",
            latlng: "",
            candidateInputGeoname: "",
            ...data,
        }).toString();
    }
    async updateGeoDistances(data, options) {
        return await this.request({
            schema: Schema.Unknown,
            method: "GET",
            url: `/ra/api/updateGeoDistances?${this.commuteQuery(data)}`,
            options,
        });
    }
    async updateCommuteTimes(data, options) {
        return await this.request({
            schema: Schema.Unknown,
            method: "GET",
            url: `/ra/api/updateCommuteTimes?${this.commuteQuery(data)}`,
            options,
        });
    }
    async subPkg(body, options) {
        return await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: "/ra/api/subPkg",
            data: body,
            options,
        });
    }
    async feedback(body, options) {
        return await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: "/ra/api/feedback",
            data: body,
            options,
        });
    }
    async findConnectedContacts(data, options) {
        const encode = Schema.encodeSync(ContactSearchRequest);
        return await this.request({
            schema: ContactSearchResult,
            method: "POST",
            url: `/api/meeting/findconnectedcontacts`,
            data: encode(data),
            options,
        });
    }
    async setConnectedContact(data, options) {
        const encode = Schema.encodeSync(ContactSelectRequest);
        await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: `/api/meeting/setconnectedcontact`,
            data: encode(data),
            options,
        });
    }
    async logEvent(iId, message, context, options) {
        await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: `/api/meeting/logevent`,
            data: {
                iId,
                message,
                context,
            },
            options,
        });
    }
    async updateConnectedId({ iId, ...data }, options) {
        await this.request({
            schema: Schema.Unknown,
            method: "POST",
            url: `/interview/updateconnectedid/${iId}`,
            data,
            options,
        });
    }
}
