export default function clearfix() {
    return {
        "::before": {
            display: "table",
            content: "''",
            lineHeight: 0,
        },
        "::after": {
            display: "table",
            content: "''",
            lineHeight: 0,
            clear: "both",
        },
    };
}
