import { Schema } from "effect";
// type GraphRefrence = {
//   iId: string;
//   details: {
//     tabId: string;
//     meetingTemplateId?: null;
//     outerMeetingAutoAdmittedUsers?: null;
//     subject: string;
//     isBroadcast: false;
//     joinMeetingIdSettings: {
//       isPasscodeRequired: true;
//       joinMeetingId: string;
//       passcode: string;
//     };
//     broadcastSettings?: null;
//     meetingType?: null;
//     chatInfo: {
//       threadId: string;
//       replyChainMessageId?: null;
//       messageId: "0";
//     };
//     allowedPresenters: "everyone";
//     allowRecording: true;
//     chatRestrictions?: null;
//     allowTeamworkReactions: true;
//     joinWebUrl: string;
//     allowAttendeeToEnableCamera: true;
//     meetingsMigrationMode?: null;
//     allowAttendeeToEnableMic: true;
//     anonymizeIdentityForRoles?: never[];
//     meetingCode: string;
//     meetingInfo?: null;
//     id: string;
//     joinUrl: string;
//     creationDateTime: string;
//     participants: {
//       attendees: {
//         identity: {
//           application?: {
//             id: string;
//           };
//           user?: {
//             tenantId: string;
//             id: string;
//             identityProvider: "AAD";
//           };
//         };
//         upn?: string;
//         role?: "attendee";
//       }[];
//       organizer: {
//         upn: string;
//         role: "presenter";
//         identity: {
//           application?: null;
//           device?: null;
//           user: {
//             displayName?: null;
//             tenantId: string;
//             id: string;
//             identityProvider: "AAD";
//           };
//         };
//       };
//     };
//     allowParticipantsToChangeName: false;
//     allowTranscription: true;
//     capabilities?: never[];
//     videoTeleconferenceId: string;
//     iCalUid?: null;
//     externalId?: null;
//     recordAutomatically: false;
//     lobbyBypassSettings: {
//       isDialInBypassEnabled: false;
//       scope: "organization";
//     };
//     endDateTime: string;
//     allowMeetingChat: "enabled";
//     shareMeetingChatHistoryDefault: "none";
//     isEntryExitAnnounced: false;
//     startDateTime: string;
//     autoAdmittedUsers: "everyoneInCompany";
//     joinInformation: {
//       contentType: "html";
//       content: string;
//     };
//     watermarkProtection?: null;
//     audioConferencing?: null | {
//       dialinUrl: string;
//       tollFreeNumber?: string | null;
//       conferenceId: string;
//       tollNumber: string;
//       tollFreeNumbers?: (string | null)[];
//       tollNumbers: string[];
//     };
//     allowBreakoutRooms?: true;
//     allowPowerpointSharing?: true;
//     allowWhiteboard?: true;
//     allowLiveShare?: "enabled";
//   };
// };
// TODO: Update this to be a proper GraphReference
export const GraphReferenceDetails = Schema.Record({
    key: Schema.NonEmptyString,
    value: Schema.Unknown,
});
export const GraphReference = Schema.Struct({
    iId: Schema.NonEmptyString,
    details: GraphReferenceDetails,
});
