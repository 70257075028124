import { Schema } from "effect";
export const DayOfWeek = Schema.Literal("sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday");
export const WorkingHours = Schema.Struct({
    // The days of the week on which the user works.
    daysOfWeek: Schema.Array(DayOfWeek),
    // The time of the day that the user starts working.
    startTime: Schema.String,
    // The time of the day that the user stops working.
    endTime: Schema.String,
});
export const TimeSlot = Schema.Struct({
    start: Schema.Date,
    end: Schema.Date,
});
export const PersonConfig = Schema.Struct({
    name: Schema.optional(Schema.String),
    email: Schema.optional(Schema.String),
    phoneNumber: Schema.optional(Schema.String),
});
export const MeetingTimes = Schema.Struct({
    timeSlots: Schema.Array(TimeSlot),
    interviewer: PersonConfig,
    interviewee: PersonConfig,
    isGenericLink: Schema.Boolean,
});
export const IntervieweeConfig = Schema.Struct({
    name: Schema.String,
    email: Schema.String,
    phoneCountry: Schema.String,
    phoneNumber: Schema.String,
    brand: Schema.String,
    smsOptIn: Schema.Boolean,
    smsOptOut: Schema.Boolean,
    recordOptIn: Schema.NullishOr(Schema.Boolean),
    recordOptOut: Schema.NullishOr(Schema.Boolean),
    locale: Schema.String,
});
export const SetMeetingTime = Schema.Struct({
    timeSlot: TimeSlot,
    localTimeZone: Schema.String,
    interviewee: IntervieweeConfig,
    reschedule: Schema.Boolean,
});
