import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";

import type { Interview } from "@aglocal/schema/Interview";
import { PostMeetingForm } from "@aglocal/schema/PostMeetingForm";

import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { Schema } from "effect";

export const PostMeetingFormWithSummary = Schema.extend(
  PostMeetingForm,
  Schema.Struct({
    summary: Schema.Struct({
      name: Schema.optional(Schema.String),
      settings: Schema.optional(
        Schema.Struct({
          model: Schema.optional(Schema.String),
          pattern: Schema.optional(
            Schema.Literal("MAP_REDUCE", "REFINE", "STUFF"),
          ),
        }),
      ),
      value: Schema.String,
    }),
  }),
);

export type PostMeetingFormWithSummary = typeof PostMeetingFormWithSummary.Type;

function useDefaultValues(interview: Interview): PostMeetingFormWithSummary {
  const { id, interviewee, inMeetingNotes = "" } = interview;
  const { connectedDetails } = interviewee ?? {};
  const { candidateStatus } = connectedDetails ?? {};

  return useMemo(
    () => ({
      id,
      meetingType: candidateStatus === "Current" ? "STP" : "G2",
      jobTitle: "",
      currentCompany: "",
      notes: inMeetingNotes,
      summary: { value: "" },
      skills: [],
      skillsComments: "",
      goalsAndInterests: "",
      locationComments: "",
      additionalDetails: {}, // Use the default object here
    }),
    [id, candidateStatus, inMeetingNotes],
  );
}

export function usePostMeetingFormWithSummary(interview: Interview) {
  const defaultValues = useDefaultValues(interview);

  const form = useForm({
    defaultValues,
    resolver: effectTsResolver(PostMeetingFormWithSummary),
  });

  const idRef = useRef(interview.id);
  const { reset } = form;

  useEffect(() => {
    reset(defaultValues, { keepDirtyValues: idRef.current === interview.id });
    idRef.current = interview.id;
  }, [idRef, interview.id, defaultValues, reset]);

  return form;
}

function formatNotes(notes: string, summary: string): string {
  if (summary.trim() === "") {
    return notes;
  }

  if (notes.trim() === "") {
    return summary;
  }

  return `${notes}\n\nMeeting Summary:\n${summary}`;
}

export function getConnectedDetailsUpdate({
  notes,
  summary,
  ...data
}: PostMeetingFormWithSummary): PostMeetingForm {
  return {
    ...data,
    notes: formatNotes(notes, summary.value),
  };
}
