import { makeStyles } from "@fluentui/react-components";
import { mediaQuery } from "@aglocal/web/helpers/responsive";
function percentage(value) {
    return `${100 * value}%`;
}
function push(value) {
    return {
        left: value,
    };
}
function pull(value) {
    return {
        right: value,
    };
}
function offset(value) {
    return {
        marginLeft: value,
    };
}
const Size = {
    [1]: percentage(1 / 12),
    [2]: percentage(2 / 12),
    [3]: percentage(3 / 12),
    [4]: percentage(4 / 12),
    [5]: percentage(5 / 12),
    [6]: percentage(6 / 12),
    [7]: percentage(7 / 12),
    [8]: percentage(8 / 12),
    [9]: percentage(9 / 12),
    [10]: percentage(10 / 12),
    [11]: percentage(11 / 12),
    [12]: percentage(12 / 12),
};
export const useResponsiveStyles = makeStyles({
    // Small Screens
    sm12: {
        width: Size[12],
    },
    sm11: {
        width: Size[11],
    },
    sm10: {
        width: Size[10],
    },
    sm9: {
        width: Size[9],
    },
    sm8: {
        width: Size[8],
    },
    sm7: {
        width: Size[7],
    },
    sm6: {
        width: Size[6],
    },
    sm5: {
        width: Size[5],
    },
    sm4: {
        width: Size[4],
    },
    sm3: {
        width: Size[3],
    },
    sm2: {
        width: Size[2],
    },
    sm1: {
        width: Size[1],
    },
    smPull12: {
        ...pull(Size[12]),
    },
    smPull11: {
        ...pull(Size[11]),
    },
    smPull10: {
        ...pull(Size[10]),
    },
    smPull9: {
        ...pull(Size[9]),
    },
    smPull8: {
        ...pull(Size[8]),
    },
    smPull7: {
        ...pull(Size[7]),
    },
    smPull6: {
        ...pull(Size[6]),
    },
    smPull5: {
        ...pull(Size[5]),
    },
    smPull4: {
        ...pull(Size[4]),
    },
    smPull3: {
        ...pull(Size[3]),
    },
    smPull2: {
        ...pull(Size[2]),
    },
    smPull1: {
        ...pull(Size[1]),
    },
    smPull0: {
        ...pull("auto"),
    },
    smPush12: {
        ...push(Size[12]),
    },
    smPush11: {
        ...push(Size[11]),
    },
    smPush10: {
        ...push(Size[10]),
    },
    smPush9: {
        ...push(Size[9]),
    },
    smPush8: {
        ...push(Size[8]),
    },
    smPush7: {
        ...push(Size[7]),
    },
    smPush6: {
        ...push(Size[6]),
    },
    smPush5: {
        ...push(Size[5]),
    },
    smPush4: {
        ...push(Size[4]),
    },
    smPush3: {
        ...push(Size[3]),
    },
    smPush2: {
        ...push(Size[2]),
    },
    smPush1: {
        ...push(Size[1]),
    },
    smPush0: {
        ...push("auto"),
    },
    smOffset12: {
        ...offset(Size[12]),
    },
    smOffset11: {
        ...offset(Size[11]),
    },
    smOffset10: {
        ...offset(Size[10]),
    },
    smOffset9: {
        ...offset(Size[9]),
    },
    smOffset8: {
        ...offset(Size[8]),
    },
    smOffset7: {
        ...offset(Size[7]),
    },
    smOffset6: {
        ...offset(Size[6]),
    },
    smOffset5: {
        ...offset(Size[5]),
    },
    smOffset4: {
        ...offset(Size[4]),
    },
    smOffset3: {
        ...offset(Size[3]),
    },
    smOffset2: {
        ...offset(Size[2]),
    },
    smOffset1: {
        ...offset(Size[1]),
    },
    smOffset0: {
        ...offset("0"),
    },
    // Medium Screens
    md12: {
        [mediaQuery({ min: "md" })]: {
            width: Size[12],
        },
    },
    md11: {
        [mediaQuery({ min: "md" })]: {
            width: Size[11],
        },
    },
    md10: {
        [mediaQuery({ min: "md" })]: {
            width: Size[10],
        },
    },
    md9: {
        [mediaQuery({ min: "md" })]: {
            width: Size[9],
        },
    },
    md8: {
        [mediaQuery({ min: "md" })]: {
            width: Size[8],
        },
    },
    md7: {
        [mediaQuery({ min: "md" })]: {
            width: Size[7],
        },
    },
    md6: {
        [mediaQuery({ min: "md" })]: {
            width: Size[6],
        },
    },
    md5: {
        [mediaQuery({ min: "md" })]: {
            width: Size[5],
        },
    },
    md4: {
        [mediaQuery({ min: "md" })]: {
            width: Size[4],
        },
    },
    md3: {
        [mediaQuery({ min: "md" })]: {
            width: Size[3],
        },
    },
    md2: {
        [mediaQuery({ min: "md" })]: {
            width: Size[2],
        },
    },
    md1: {
        [mediaQuery({ min: "md" })]: {
            width: Size[1],
        },
    },
    mdPull12: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[12]),
        },
    },
    mdPull11: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[11]),
        },
    },
    mdPull10: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[10]),
        },
    },
    mdPull9: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[9]),
        },
    },
    mdPull8: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[8]),
        },
    },
    mdPull7: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[7]),
        },
    },
    mdPull6: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[6]),
        },
    },
    mdPull5: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[5]),
        },
    },
    mdPull4: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[4]),
        },
    },
    mdPull3: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[3]),
        },
    },
    mdPull2: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[2]),
        },
    },
    mdPull1: {
        [mediaQuery({ min: "md" })]: {
            ...pull(Size[1]),
        },
    },
    mdPull0: {
        [mediaQuery({ min: "md" })]: {
            ...pull("auto"),
        },
    },
    mdPush12: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[12]),
        },
    },
    mdPush11: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[11]),
        },
    },
    mdPush10: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[10]),
        },
    },
    mdPush9: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[9]),
        },
    },
    mdPush8: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[8]),
        },
    },
    mdPush7: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[7]),
        },
    },
    mdPush6: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[6]),
        },
    },
    mdPush5: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[5]),
        },
    },
    mdPush4: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[4]),
        },
    },
    mdPush3: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[3]),
        },
    },
    mdPush2: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[2]),
        },
    },
    mdPush1: {
        [mediaQuery({ min: "md" })]: {
            ...push(Size[1]),
        },
    },
    mdPush0: {
        [mediaQuery({ min: "md" })]: {
            ...push("auto"),
        },
    },
    mdOffset12: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[12]),
        },
    },
    mdOffset11: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[11]),
        },
    },
    mdOffset10: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[10]),
        },
    },
    mdOffset9: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[9]),
        },
    },
    mdOffset8: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[8]),
        },
    },
    mdOffset7: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[7]),
        },
    },
    mdOffset6: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[6]),
        },
    },
    mdOffset5: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[5]),
        },
    },
    mdOffset4: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[4]),
        },
    },
    mdOffset3: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[3]),
        },
    },
    mdOffset2: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[2]),
        },
    },
    mdOffset1: {
        [mediaQuery({ min: "md" })]: {
            ...offset(Size[1]),
        },
    },
    mdOffset0: {
        [mediaQuery({ min: "md" })]: {
            ...offset("0"),
        },
    },
    // Large screens (lg)
    lg12: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[12],
        },
    },
    lg11: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[11],
        },
    },
    lg10: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[10],
        },
    },
    lg9: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[9],
        },
    },
    lg8: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[8],
        },
    },
    lg7: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[7],
        },
    },
    lg6: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[6],
        },
    },
    lg5: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[5],
        },
    },
    lg4: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[4],
        },
    },
    lg3: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[3],
        },
    },
    lg2: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[2],
        },
    },
    lg1: {
        [mediaQuery({ min: "lg" })]: {
            width: Size[1],
        },
    },
    lgPull12: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[12]),
        },
    },
    lgPull11: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[11]),
        },
    },
    lgPull10: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[10]),
        },
    },
    lgPull9: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[9]),
        },
    },
    lgPull8: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[8]),
        },
    },
    lgPull7: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[7]),
        },
    },
    lgPull6: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[6]),
        },
    },
    lgPull5: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[5]),
        },
    },
    lgPull4: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[4]),
        },
    },
    lgPull3: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[3]),
        },
    },
    lgPull2: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[2]),
        },
    },
    lgPull1: {
        [mediaQuery({ min: "lg" })]: {
            ...pull(Size[1]),
        },
    },
    lgPull0: {
        [mediaQuery({ min: "lg" })]: {
            ...pull("auto"),
        },
    },
    lgPush12: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[12]),
        },
    },
    lgPush11: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[11]),
        },
    },
    lgPush10: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[10]),
        },
    },
    lgPush9: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[9]),
        },
    },
    lgPush8: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[8]),
        },
    },
    lgPush7: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[7]),
        },
    },
    lgPush6: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[6]),
        },
    },
    lgPush5: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[5]),
        },
    },
    lgPush4: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[4]),
        },
    },
    lgPush3: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[3]),
        },
    },
    lgPush2: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[2]),
        },
    },
    lgPush1: {
        [mediaQuery({ min: "lg" })]: {
            ...push(Size[1]),
        },
    },
    lgPush0: {
        [mediaQuery({ min: "lg" })]: {
            ...push("auto"),
        },
    },
    lgOffset12: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[12]),
        },
    },
    lgOffset11: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[11]),
        },
    },
    lgOffset10: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[10]),
        },
    },
    lgOffset9: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[9]),
        },
    },
    lgOffset8: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[8]),
        },
    },
    lgOffset7: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[7]),
        },
    },
    lgOffset6: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[6]),
        },
    },
    lgOffset5: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[5]),
        },
    },
    lgOffset4: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[4]),
        },
    },
    lgOffset3: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[3]),
        },
    },
    lgOffset2: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[2]),
        },
    },
    lgOffset1: {
        [mediaQuery({ min: "lg" })]: {
            ...offset(Size[1]),
        },
    },
    lgOffset0: {
        [mediaQuery({ min: "lg" })]: {
            ...offset("0"),
        },
    },
    // Extra large Screens (xl)
    xl12: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[12],
        },
    },
    xl11: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[11],
        },
    },
    xl10: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[10],
        },
    },
    xl9: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[9],
        },
    },
    xl8: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[8],
        },
    },
    xl7: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[7],
        },
    },
    xl6: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[6],
        },
    },
    xl5: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[5],
        },
    },
    xl4: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[4],
        },
    },
    xl3: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[3],
        },
    },
    xl2: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[2],
        },
    },
    xl1: {
        [mediaQuery({ min: "xl" })]: {
            width: Size[1],
        },
    },
    xlPull12: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[12]),
        },
    },
    xlPull11: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[11]),
        },
    },
    xlPull10: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[10]),
        },
    },
    xlPull9: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[9]),
        },
    },
    xlPull8: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[8]),
        },
    },
    xlPull7: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[7]),
        },
    },
    xlPull6: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[6]),
        },
    },
    xlPull5: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[5]),
        },
    },
    xlPull4: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[4]),
        },
    },
    xlPull3: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[3]),
        },
    },
    xlPull2: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[2]),
        },
    },
    xlPull1: {
        [mediaQuery({ min: "xl" })]: {
            ...pull(Size[1]),
        },
    },
    xlPull0: {
        [mediaQuery({ min: "xl" })]: {
            ...pull("auto"),
        },
    },
    xlPush12: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[12]),
        },
    },
    xlPush11: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[11]),
        },
    },
    xlPush10: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[10]),
        },
    },
    xlPush9: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[9]),
        },
    },
    xlPush8: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[8]),
        },
    },
    xlPush7: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[7]),
        },
    },
    xlPush6: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[6]),
        },
    },
    xlPush5: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[5]),
        },
    },
    xlPush4: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[4]),
        },
    },
    xlPush3: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[3]),
        },
    },
    xlPush2: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[2]),
        },
    },
    xlPush1: {
        [mediaQuery({ min: "xl" })]: {
            ...push(Size[1]),
        },
    },
    xlPush0: {
        [mediaQuery({ min: "xl" })]: {
            ...push("auto"),
        },
    },
    xlOffset12: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[12]),
        },
    },
    xlOffset11: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[11]),
        },
    },
    xlOffset10: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[10]),
        },
    },
    xlOffset9: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[9]),
        },
    },
    xlOffset8: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[8]),
        },
    },
    xlOffset7: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[7]),
        },
    },
    xlOffset6: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[6]),
        },
    },
    xlOffset5: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[5]),
        },
    },
    xlOffset4: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[4]),
        },
    },
    xlOffset3: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[3]),
        },
    },
    xlOffset2: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[2]),
        },
    },
    xlOffset1: {
        [mediaQuery({ min: "xl" })]: {
            ...offset(Size[1]),
        },
    },
    xlOffset0: {
        [mediaQuery({ min: "xl" })]: {
            ...offset("0"),
        },
    },
    // Extra extra Screens (xxl)
    xxl12: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[12],
        },
    },
    xxl11: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[11],
        },
    },
    xxl10: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[10],
        },
    },
    xxl9: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[9],
        },
    },
    xxl8: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[8],
        },
    },
    xxl7: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[7],
        },
    },
    xxl6: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[6],
        },
    },
    xxl5: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[5],
        },
    },
    xxl4: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[4],
        },
    },
    xxl3: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[3],
        },
    },
    xxl2: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[2],
        },
    },
    xxl1: {
        [mediaQuery({ min: "xxl" })]: {
            width: Size[1],
        },
    },
    xxlPull12: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[12]),
        },
    },
    xxlPull11: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[11]),
        },
    },
    xxlPull10: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[10]),
        },
    },
    xxlPull9: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[9]),
        },
    },
    xxlPull8: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[8]),
        },
    },
    xxlPull7: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[7]),
        },
    },
    xxlPull6: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[6]),
        },
    },
    xxlPull5: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[5]),
        },
    },
    xxlPull4: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[4]),
        },
    },
    xxlPull3: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[3]),
        },
    },
    xxlPull2: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[2]),
        },
    },
    xxlPull1: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull(Size[1]),
        },
    },
    xxlPull0: {
        [mediaQuery({ min: "xxl" })]: {
            ...pull("auto"),
        },
    },
    xxlPush12: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[12]),
        },
    },
    xxlPush11: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[11]),
        },
    },
    xxlPush10: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[10]),
        },
    },
    xxlPush9: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[9]),
        },
    },
    xxlPush8: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[8]),
        },
    },
    xxlPush7: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[7]),
        },
    },
    xxlPush6: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[6]),
        },
    },
    xxlPush5: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[5]),
        },
    },
    xxlPush4: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[4]),
        },
    },
    xxlPush3: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[3]),
        },
    },
    xxlPush2: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[2]),
        },
    },
    xxlPush1: {
        [mediaQuery({ min: "xxl" })]: {
            ...push(Size[1]),
        },
    },
    xxlPush0: {
        [mediaQuery({ min: "xxl" })]: {
            ...push("auto"),
        },
    },
    xxlOffset12: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[12]),
        },
    },
    xxlOffset11: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[11]),
        },
    },
    xxlOffset10: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[10]),
        },
    },
    xxlOffset9: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[9]),
        },
    },
    xxlOffset8: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[8]),
        },
    },
    xxlOffset7: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[7]),
        },
    },
    xxlOffset6: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[6]),
        },
    },
    xxlOffset5: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[5]),
        },
    },
    xxlOffset4: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[4]),
        },
    },
    xxlOffset3: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[3]),
        },
    },
    xxlOffset2: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[2]),
        },
    },
    xxlOffset1: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset(Size[1]),
        },
    },
    xxlOffset0: {
        [mediaQuery({ min: "xxl" })]: {
            ...offset("0"),
        },
    },
    // Extra extra extra large screens (xxxl)
    xxxl12: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[12],
        },
    },
    xxxl11: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[11],
        },
    },
    xxxl10: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[10],
        },
    },
    xxxl9: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[9],
        },
    },
    xxxl8: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[8],
        },
    },
    xxxl7: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[7],
        },
    },
    xxxl6: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[6],
        },
    },
    xxxl5: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[5],
        },
    },
    xxxl4: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[4],
        },
    },
    xxxl3: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[3],
        },
    },
    xxxl2: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[2],
        },
    },
    xxxl1: {
        [mediaQuery({ min: "xxxl" })]: {
            width: Size[1],
        },
    },
    xxxlPull12: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[12]),
        },
    },
    xxxlPull11: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[11]),
        },
    },
    xxxlPull10: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[10]),
        },
    },
    xxxlPull9: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[9]),
        },
    },
    xxxlPull8: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[8]),
        },
    },
    xxxlPull7: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[7]),
        },
    },
    xxxlPull6: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[6]),
        },
    },
    xxxlPull5: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[5]),
        },
    },
    xxxlPull4: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[4]),
        },
    },
    xxxlPull3: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[3]),
        },
    },
    xxxlPull2: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[2]),
        },
    },
    xxxlPull1: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull(Size[1]),
        },
    },
    xxxlPull0: {
        [mediaQuery({ min: "xxxl" })]: {
            ...pull("auto"),
        },
    },
    xxxlPush12: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[12]),
        },
    },
    xxxlPush11: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[11]),
        },
    },
    xxxlPush10: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[10]),
        },
    },
    xxxlPush9: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[9]),
        },
    },
    xxxlPush8: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[8]),
        },
    },
    xxxlPush7: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[7]),
        },
    },
    xxxlPush6: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[6]),
        },
    },
    xxxlPush5: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[5]),
        },
    },
    xxxlPush4: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[4]),
        },
    },
    xxxlPush3: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[3]),
        },
    },
    xxxlPush2: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[2]),
        },
    },
    xxxlPush1: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push(Size[1]),
        },
    },
    xxxlPush0: {
        [mediaQuery({ min: "xxxl" })]: {
            ...push("auto"),
        },
    },
    xxxlOffset12: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[12]),
        },
    },
    xxxlOffset11: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[11]),
        },
    },
    xxxlOffset10: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[10]),
        },
    },
    xxxlOffset9: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[9]),
        },
    },
    xxxlOffset8: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[8]),
        },
    },
    xxxlOffset7: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[7]),
        },
    },
    xxxlOffset6: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[6]),
        },
    },
    xxxlOffset5: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[5]),
        },
    },
    xxxlOffset4: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[4]),
        },
    },
    xxxlOffset3: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[3]),
        },
    },
    xxxlOffset2: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[2]),
        },
    },
    xxxlOffset1: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset(Size[1]),
        },
    },
    xxxlOffset0: {
        [mediaQuery({ min: "xxxl" })]: {
            ...offset("0"),
        },
    },
    smHidden: {
        [mediaQuery({ max: "sm" })]: {
            display: "none !important",
        },
    },
    mdHidden: {
        [mediaQuery({ min: "md", max: "md" })]: {
            display: "none !important",
        },
    },
    lgHidden: {
        [mediaQuery({ min: "lg", max: "lg" })]: {
            display: "none !important",
        },
    },
    xlHidden: {
        [mediaQuery({ min: "xl", max: "xl" })]: {
            display: "none !important",
        },
    },
    xxlHidden: {
        [mediaQuery({ min: "xxl", max: "xxl" })]: {
            display: "none !important",
        },
    },
    xxxlHidden: {
        [mediaQuery({ min: "xxxl", max: "xxxl" })]: {
            display: "none !important",
        },
    },
    smHiddenUp: {
        [mediaQuery({ min: "sm" })]: {
            display: "none !important",
        },
    },
    mdHiddenUp: {
        [mediaQuery({ min: "md" })]: {
            display: "none !important",
        },
    },
    lgHiddenUp: {
        [mediaQuery({ min: "lg" })]: {
            display: "none !important",
        },
    },
    xlHiddenUp: {
        [mediaQuery({ min: "xl" })]: {
            display: "none !important",
        },
    },
    xxlHiddenUp: {
        [mediaQuery({ min: "xxl" })]: {
            display: "none !important",
        },
    },
    xxxlHiddenUp: {
        [mediaQuery({ min: "xxxl" })]: {
            display: "none !important",
        },
    },
    smHiddenDown: {
        [mediaQuery({ max: "sm" })]: {
            display: "none !important",
        },
    },
    mdHiddenDown: {
        [mediaQuery({ max: "md" })]: {
            display: "none !important",
        },
    },
    lgHiddenDown: {
        [mediaQuery({ max: "lg" })]: {
            display: "none !important",
        },
    },
    xlHiddenDown: {
        [mediaQuery({ max: "xl" })]: {
            display: "none !important",
        },
    },
    xxlHiddenDown: {
        [mediaQuery({ max: "xxl" })]: {
            display: "none !important",
        },
    },
    xxxlHiddenDown: {
        [mediaQuery({ max: "xxxl" })]: {
            display: "none !important",
        },
    },
});
