import { jsx as _jsx } from "@fluentui/react-jsx-runtime/jsx-runtime";
/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import { forwardRef } from "react";
import { assertSlots, getIntrinsicElementProps, makeStyles, mergeClasses, slot, } from "@fluentui/react-components";
import { useResponsiveStyles } from "@aglocal/web/styles/responsive";
const useStyles = makeStyles({
    root: {
        position: "relative",
        minHeight: "1px",
        paddingLeft: "8px",
        paddingRight: "8px",
        boxSizing: "border-box",
        float: "left",
    },
});
function useCol({ sm, md, lg, xl, xxl, xxxl, ...props }, ref) {
    return {
        components: { root: "div" },
        sizes: { sm, md, lg, xl, xxl, xxxl },
        root: slot.always(getIntrinsicElementProps("div", { ref, ...props }), {
            elementType: "div",
        }),
    };
}
function useColStyles(state) {
    const styles = useStyles();
    const responsive = useResponsiveStyles();
    const classes = [];
    let size;
    for (size in state.sizes) {
        const spec = state.sizes[size];
        if (spec == null) {
            continue;
        }
        if (typeof spec === "number" || typeof spec === "string") {
            classes.push(responsive[`${size}${spec}`]);
        }
        else {
            classes.push(responsive[`${size}${spec.span}`]);
            if (spec.pull != null) {
                classes.push(responsive[`${size}Pull${spec.pull}`]);
            }
            if (spec.push != null) {
                classes.push(responsive[`${size}Push${spec.push}`]);
            }
            if (spec.offset != null) {
                classes.push(responsive[`${size}Offset${spec.offset}`]);
            }
            if (spec.hidden === "up") {
                classes.push(responsive[`${size}HiddenUp`]);
            }
            else if (spec.hidden === "down") {
                classes.push(responsive[`${size}HiddenDown`]);
            }
            else if (spec.hidden) {
                classes.push(responsive[`${size}Hidden`]);
            }
        }
    }
    state.root.className = mergeClasses(styles.root, ...classes, state.root.className);
}
function renderCol(state) {
    assertSlots(state);
    return _jsx(state.root, {});
}
export const Col = forwardRef((props, ref) => {
    const state = useCol(props, ref);
    useColStyles(state);
    return renderCol(state);
});
Col.displayName = "Col";
export default Col;
