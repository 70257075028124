import { Schema } from "effect";
import { collection, doc, query, } from "firebase/firestore";
export class SchemaConverter {
    schema;
    encode;
    decode;
    options = {
        errors: "all",
        onExcessProperty: "preserve",
    };
    constructor(schema) {
        this.schema = schema;
        this.encode = Schema.encodeSync(this.schema);
        this.decode = Schema.decodeUnknownSync(this.schema);
    }
    toFirestore(data) {
        return this.encode(data, this.options);
    }
    fromFirestore(snapshot) {
        return this.decode(snapshot.data());
    }
}
export class FirestoreCollection {
    ref;
    schema;
    converters;
    constructor(ref, schema) {
        this.ref = ref;
        this.schema = schema;
        this.converters = this.buildConverters(schema);
    }
    buildConverters(schema) {
        const converters = {};
        for (const [key, value] of Object.entries(schema ?? {})) {
            if (value.schema) {
                converters[key] = new SchemaConverter(value.schema);
            }
        }
        return converters;
    }
    doc(id) {
        return doc(this.ref, id);
    }
    query(...queryConstraints) {
        return query(this.ref, ...queryConstraints);
    }
    subcollections(id) {
        const rootRef = this.doc(id);
        return Object.fromEntries(Object.entries(this.schema ?? {}).map(([key, { name = key, subcollections }]) => {
            const ref = collection(rootRef, name).withConverter(this.converters[key]);
            return [key, new FirestoreCollection(ref, subcollections)];
        }));
    }
}
export default function create(db, shape) {
    return Object.fromEntries(Object.entries(shape).map(([key, { name = key, schema, subcollections }]) => {
        const ref = schema
            ? collection(db, name).withConverter(new SchemaConverter(schema))
            : collection(db, name);
        return [key, new FirestoreCollection(ref, subcollections)];
    }));
}
