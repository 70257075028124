import { Button } from "@fluentui/react-components";

import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogBody,
} from "@fluentui/react-dialog";

export interface MeetingErrorModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export default function MeetingErrorModal({
  onConfirm,
  onCancel,
}: MeetingErrorModalProps) {
  return (
    <Dialog open modalType="alert" onOpenChange={onCancel}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Confirm Meeting Details</DialogTitle>
          <DialogContent>
            <p>
              By submitting, you confirm that you have reviewed and approved the
              meeting summary and extracted data for accuracy. This information
              will be added to the candidate&apos;s record in Connected. Do you
              want to proceed?
            </p>
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={onConfirm}>
              Confirm
            </Button>
            <DialogTrigger>
              <Button appearance="secondary">Cancel</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
